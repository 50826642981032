.default {
    background-color: darkslateblue;
    color: cornsilk;
    text-align: center;
    display: block;
}

body {
    background-color: darkslateblue;
}

:visited {
    color: blueviolet;
}

:link {
    color: cyan;
}

